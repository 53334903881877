import React from 'react'
import Modal from 'react-responsive-modal'
import { connect, useSelector } from 'react-redux'
import styles from './styles.module.scss'
import { ChoiceQuestion, MultichoiceQuestion, FinalWindow } from './Components'
import { TestContext } from '../../utils/context'
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import Timer from '../../components/Timer/Timer'
import { addQuestionAnswer, getCertificate } from '../../actions/tests'
import classNames from 'classnames'


const QuestionModal = ({
  isOpen,
  closeTestHandler,
  nextQuestion,
  prevQuestion,
  nextButtonDisabled,
  prevButtonDisabled,
  questionInd,
  questions,
  testFinished,
  testId,
  timeOver,
  setTimeOver,
  finishTest,
  setCurrentQuestion,
  setQuestionPassed,
  time,
  stat
}) => {
  
  const questionTitle = testFinished ? 'Результат' : `${questionInd + 1} / ${questions?.length}`

  const navPanel = (
    <div className={styles.questionNumbers}>
      {questions.map((q, ind) => {
        const itemClassNames = classNames({[styles.passedQuestion]: q.passed, [styles.activeQuestion]: ind === questionInd})
        return (
          <div className={itemClassNames} key={ind} onClick={setCurrentQuestion(ind)}>
            {ind + 1}
          </div>
        )
      }
        
      )}
    </div>
  )

  const renderQuestion = (question, index) => {
    const visible = questionInd === index
    const props = { question, visible, navPanel, setQuestionPassed: setQuestionPassed(index) }
    switch (question.question_type) {
      case 'multiple': return <MultichoiceQuestion key={index} {...props}/>
      default: return <ChoiceQuestion key={index} {...props} />
    }
  }

  const contextValue = {
    addQuestionAnswer,
    getCertificate,
    stat,
    nextQuestion,
    closeTestHandler,
    questions,
    testId
  }
  
  const arrowStyles = {visibility: testFinished ? 'hidden' : 'visible'}

  return (
    <Modal
      style={{ content: {borderColor: 'grey'}}}
      open={isOpen}
      // open={true}
      showCloseIcon={false}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      classNames={{ modal: styles.modalWrap }}
      center
    >
      <TestContext.Provider value={contextValue}>
        <div className={styles.modal}>

          <FaChevronLeft style={arrowStyles} disabled={prevButtonDisabled} onClick={prevQuestion}/>

          <div>
            <div className={styles.timerBlock}>
              <Timer over={timeOver} setOver={setTimeOver} handleFinal={finishTest} minutes={time || 15}/>
            </div>
            <h5>{questionTitle}</h5>
            {questions?.map(renderQuestion)}
            {testFinished && <FinalWindow/>}
          </div>

          <FaChevronRight style={arrowStyles} disabled={nextButtonDisabled} onClick={nextQuestion}/>
        
        </div>

      </TestContext.Provider>
    </Modal>
  )
}

export default QuestionModal