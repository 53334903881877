export function shuffle (originArray) {
  
  const array = JSON.parse(JSON.stringify(originArray))
  
  let currentIndex = array.length;

  // While there remain elements to shuffle...
  while (currentIndex != 0) {

    // Pick a remaining element...
    let randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array
}

export function interpolateColor(value) {
  // Красный цвет при value = 0
  const redStart = { r: 255, g: 0, b: 0 };
  
  // Зеленый цвет при value = 1
  const greenEnd = { r: 0, g: 255, b: 0 };
  
  // Линейная интерполяция
  const r = Math.round(redStart.r + (greenEnd.r - redStart.r) * value);
  const g = Math.round(redStart.g + (greenEnd.g - redStart.g) * value);
  const b = Math.round(redStart.b + (greenEnd.b - redStart.b) * value);
  
  return `rgb(${r}, ${g}, ${b})`;
}