import * as types from '../actions/types';

const initialState = {
  list: [],
  current: null,
  stat: null,
  token: '',
  loading: false
};

export default function tests (state = initialState, action) {
  switch (action.type) {
    
    case types.SET_TESTS_CURRENT:
      return {
        ...state,
        current: action.payload
      };
    
    case types.SET_TESTS_LIST:
      return {
        ...state,
        list: action.payload
      };
    
    case types.SET_TESTS_TOKEN:
      return {
        ...state,
        token: action.payload
      };
    
    case types.SET_TESTS_ANSWERS_STAT:
      return {
        ...state,
        stat: action.payload
      };
    
    case types.SET_TESTS_LOADING:
      return {
        ...state,
        loading: action.payload
      };

    default:
      return state;
  }
}
