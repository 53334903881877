export const SET_AUTH_LOADING = 'SET_AUTH_LOADING'
export const SET_AUTH_DATA = 'SET_AUTH_DATA'
export const LOG_OUT = 'LOG_OUT'



export const SET_EDITOR_TEST_LIST = 'SET_EDITOR_TEST_LIST'
export const SET_EDITOR_CURRENT_TEST = 'SET_EDITOR_CURRENT_TEST'
export const SET_EDITOR_LOADING = 'SET_EDITOR_LOADING'
export const SET_EDITOR_QESTIONS_LIST = 'SET_EDITOR_QESTIONS_LIST'
export const SET_EDITOR_QESTIONS_CURRENT = 'SET_EDITOR_QESTIONS_CURRENT'
export const SET_EDITOR_QESTIONS_LOADING = 'SET_EDITOR_QESTIONS_LOADING'

export const SET_TESTS_LIST = 'SET_TESTS_LIST'
export const SET_TESTS_CURRENT = 'SET_TESTS_CURRENT'
export const SET_TESTS_TOKEN = 'SET_TESTS_TOKEN'
export const SET_TESTS_LOADING = 'SET_TESTS_LOADING'
export const SET_TESTS_QUESTIONS_LIST = 'SET_TESTS_QUESTIONS_LIST'
export const SET_TESTS_QUESTIONS_CURRENT = 'SET_TESTS_QUESTIONS_CURRENT'
export const SET_TESTS_QUESTIONS_LOADING = 'SET_TESTS_QUESTIONS_LOADING'
export const SET_TESTS_ANSWERS_STAT = 'SET_TESTS_ANSWERS_STAT'
