import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { deleteQuestion, getCurrentTest } from '../../actions/editor'
import AddEditTestModal from '../../components/AddEditTestModal'
import { IoIosClose, IoMdCreate } from 'react-icons/io'
import QuestionModal from './QuestionModal'
import ReactLoader from 'react-loader'
import Confirmation from '../../components/Confirmation/Confirmation'
import parse from 'html-react-parser'

const TestEdition = () => {
  
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { testId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams();
  const { current, loading } = useSelector(state => state.editor)
  
  const questionId = searchParams.get('question')

  const [editModal, setEditModal] = useState(false)
  const [deleteId, setDeleteId] = useState(null)

  useEffect(() => {
    dispatch(getCurrentTest(testId))
  }, [])

  const goToQuestion = (id) => () => {
    setSearchParams({ question: id }, {replace: true})
  }
  
  const renderQuestion = (question) => {
    return (
      <li onClick={goToQuestion(question.id)}>
        <div>
          <p>
            {question.question_text}
            <span>[{question.points || 0}]</span>
          </p>
          <IoIosClose color='red' size={46} title='Удалить' onClick={deleteQuestionHandler(question.id)}/>
        </div>
      </li>
    )
  }

  const setModalState = (state) => () => setEditModal(state)

  const deleteQuestionHandler = (id) => (e) => {
    e.stopPropagation()
    setDeleteId(id)
  }

  const acceptConfirmation = () => {
    dispatch(deleteQuestion(deleteId))
    setDeleteId(null)
  }

  const modalsClosed = !editModal && !questionId && !deleteId

  if (modalsClosed && loading) return <ReactLoader/>;

  if (!current) return null;


  return (
      <div className={styles.wrapper}>
        
        <div className={styles.titleBlock}>
          <h2>{parse(current.title)}</h2>
          <IoMdCreate size={25} onClick={setModalState(true)}/>
        </div>

        <p>Описание:</p>
        <div>{parse(current.description)}</div>

        <div className={styles.infoBlock}>
          <p>Количество вопросов:</p>
          <span>{current.questions.length}</span>
        </div>
        
        <div className={styles.infoBlock}>
          <p>Допустимое количество баллов для прохождения тестирования:</p>
          <span>{current.pass_points || 'не задано'}</span>
        </div>
        
        <div className={styles.infoBlock}>
          <p>Максимальное количество баллов:</p>
          <span>{current.max_points || 'не задано'}</span>
        </div>
        
        <p>Вопросы:</p>
        <ol>{current.questions.map(renderQuestion)}</ol>

        <div className={styles.wrapFooterBlock}>
          <button onClick={goToQuestion('add')}>Добавить</button>
          <div className={styles.infoBlock}>
            <p>Время на прохождение тестирования:</p>
            <span>{current.training_hours} мин.</span>
          </div>
        </div>

        <AddEditTestModal
          open={editModal}
          closeModal={setModalState(false)}
          addMode={false}
          data={current}
        />

        <QuestionModal
          open={!!questionId}
          closeModal={() => setSearchParams({}, {replace: true})}
          questionId={questionId}
          addMode={!parseInt(questionId)}
          testId={testId}
        />

        <Confirmation
          open={!!deleteId}
          acceptConfirmation={acceptConfirmation}
          rejectConfirmation={() => setDeleteId(null)}
        >
          Вы уверены, что хотите удалить вопрос?
        </Confirmation>

      </div>
  )
}

export default TestEdition