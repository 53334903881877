import { api } from "../App";
import { createNotification } from "../components/Notifications/Notifications";
import { SET_TESTS_ANSWERS_STAT, SET_TESTS_CURRENT, SET_TESTS_LIST, SET_TESTS_LOADING, SET_TESTS_QUESTIONS_CURRENT, SET_TESTS_QUESTIONS_LOADING, SET_TESTS_TOKEN } from "./types";

export const setToken = (token) => ({ type: SET_TESTS_TOKEN, payload: token })

export const getToken = (fio) => async (dispatch, getState) => {
  let token = ''
  try {
    const { data } = await api.post('/user/register', {fio})
    token = data.access_token
  } catch (ERR) {
    createNotification('error', 'Ошибка получения токена')
  } finally {
    return token
  }
};

export const getTestsList = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_TESTS_LOADING, payload: true });
    const { data: payload } = await api.get('/tests')
    dispatch({ type: SET_TESTS_LIST, payload })
  } catch (ERR) {
    console.log(ERR)
  } finally {
    dispatch({ type: SET_TESTS_LOADING, payload: false });
  }
};

export const getCurrentTest = (testId) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_TESTS_LOADING, payload: true });
    const { data: payload } = await api.get(`/tests/${testId}/questions_answers`)
    dispatch({ type: SET_TESTS_CURRENT, payload })
  } catch (ERR) {
    console.log(ERR)
  } finally {
    dispatch({ type: SET_TESTS_LOADING, payload: false });
  }
};

export const getCurrentQuestion = (questionId) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_TESTS_QUESTIONS_LOADING, payload: true });
    const { data: payload } = await api.get(`/questions/${questionId}`)
    dispatch({ type: SET_TESTS_QUESTIONS_CURRENT, payload })
  } catch (ERR) {
    console.log(ERR)
  } finally {
    dispatch({ type: SET_TESTS_QUESTIONS_LOADING, payload: false });
  }
}

export const addQuestionAnswer = (data) => async (dispatch, getState) => {
  let returnState
  try {
    dispatch({ type: SET_TESTS_QUESTIONS_LOADING, payload: true });
    const { token } = getState().tests
    const headers = { Authorization: `Bearer ${token}` }
    await api.post('/user_answers/list', data, { headers })
    returnState = true
  } catch (ERR) {
    console.log(ERR)
  } finally {
    dispatch({ type: SET_TESTS_QUESTIONS_LOADING, payload: false });
    return returnState
  }
}

export const getAnswersStat = (testId, headers) => async (dispatch, getState) => {
  const {data: payload} = await api.get(`/user_answers/stats/${testId}`, { headers })
  dispatch({type: SET_TESTS_ANSWERS_STAT, payload})
}

export const getCertificate = (testId) => async (dispatch, getState) => {
  let result
  const { token } = getState().tests
  const headers = { Authorization: `Bearer ${token}`, 'Cache-Control': 'no-cache' }
  try {
    const response = await api.get(`/files/certificate/${testId}`, { headers, responseType: 'blob', cache: false })
    result = response.data
  } catch (ERR) {
    console.log(ERR)
  } finally {
    await dispatch(getAnswersStat(testId, headers))
    return result
  }
}