import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getCurrentTest, setToken } from '../../actions/tests'
import QuestionModal from './QuestionModal'
import PersonalInfoModal from './PersonalInfoModal'
import ReactLoader from 'react-loader'
import { shuffle } from '../../utils/smallHelpers'
import parse from 'html-react-parser'


const Test = () => {
  
  const dispatch = useDispatch()
  const { testId } = useParams()
  const { current, stat, loading } = useSelector(state => state.tests)
  
  const [testStatus, setTestStatus] = useState('')
  const [question, setQuestion] = useState(null)
  const [timeOver, setTimeOver] = useState(false)
  const [questionsList, setQuestionsList] = useState([])
  
  const prevButtonDisabled = testStatus === 'finished' || question - 1 < 0
  const nextButtonDisabled = testStatus === 'finished' || question + 1 >= current?.questions?.length
  
  useEffect(() => {
    dispatch(getCurrentTest(testId))
  }, [])
  
  useEffect(() => {
    current && setNewAttempt()
  }, [current])
  
  useEffect(() => {
    if (testStatus !== 'started') return;
    const testNotPAssed = questionsList.find(({passed}) => !passed)
    !testNotPAssed && finishTest()
  }, [questionsList])
  

  const setNewAttempt = () => {
    const shuffled = shuffle(current.questions)
    const list = shuffled.map(quest => ({...quest, passed: false}))
    setQuestionsList(list)
  }

  const startTestHandler = () => {
    setTestStatus('started')
    setQuestion(0)
  }
  
  const setQuestionPassed = (index) => () => {
    const newQuestionsList = JSON.parse(JSON.stringify(questionsList))
    newQuestionsList[index].passed = true
    setQuestionsList(newQuestionsList)
  }

  const closeTestHandler = () => {
    setTestStatus('')
    setQuestion(null)
    setTimeOver(false)
    dispatch(setToken(''))
    setNewAttempt()
  }
  
  const setUserInfoHandler = (token) => {
    dispatch(setToken(token))
    startTestHandler()
  }

  const setCurrentQuestion = (number) => () => {
    setQuestion(number)
  }

  const nextQuestion = () => {
    if (nextButtonDisabled) return;
    setQuestion(question + 1)
  }
  
  const prevQuestion = () => {
    setTestStatus('started')
    if (prevButtonDisabled) return;
    setQuestion(question - 1)
  }

  const finishTest = () => {
      setTestStatus('finished')
      setNewAttempt()
      setTimeOver(true)
      setQuestion(null)
  }

  
  if (!current || loading) return <ReactLoader/>;


  return (
      <div className={styles.wrapper}>
          <h2>{parse(current.title)}</h2>
          
          <div>
            <b>Описание:</b>
            <p>{parse(current.description)}</p>
          </div>
          
          <div>
            <b>Количество вопросов:</b>
            <span>{questionsList?.length}</span>
          </div>
          
          <div>
            <b>Максимальное количество баллов:</b>
            <span>{current.max_points || 'не задано'}</span>
          </div>
          
          <div>
            <b>Допустимое количество баллов для прохождения тестирования:</b>
            <span>{current.pass_points || 'не задано'}</span>
          </div>
          
          <div>
            <b>Время прохождения:</b>
            <span>{current.training_hours} мин.</span>
          </div>

          <button disabled={!questionsList?.length} onClick={() => setTestStatus('getPersonalInfo')}>
            Начать
          </button>

          <QuestionModal
            isOpen={!!testStatus && testStatus !== 'getPersonalInfo'}
            closeTestHandler={closeTestHandler}
            nextQuestion={nextQuestion}
            prevQuestion={prevQuestion}
            nextButtonDisabled={nextButtonDisabled}
            prevButtonDisabled={prevButtonDisabled}
            setCurrentQuestion={setCurrentQuestion}
            questionInd={question}
            testFinished={testStatus === 'finished'}
            testId={testId}
            timeOver={timeOver}
            time={current.training_hours}
            setTimeOver={setTimeOver}
            finishTest={finishTest}
            questions={questionsList}
            setQuestionPassed={setQuestionPassed}
            stat={stat}
          />

          <PersonalInfoModal
            open={testStatus === 'getPersonalInfo'}
            closeModal={() => setTestStatus('')}
            setUserInfo={setUserInfoHandler}
          />

      </div>
  )
}

export default Test