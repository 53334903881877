import React, { useEffect } from 'react'
import styles from './styles.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { createQuestion, getCurrentQuestion, updateQuestion } from '../../actions/editor'
import Modal from 'react-responsive-modal'
import { useFormik } from 'formik'
import { QuestionContext } from '../../utils/context'
import { ChoiceQuestion, MultipleQuestion } from './Components'
import Checkbox from '../../components/Checkbox'
import Button from '../../components/Button'
import * as Yup from 'yup'
import { createNotification } from '../../components/Notifications/Notifications'

const validationSchema = Yup.object().shape({
  points: Yup.number().nullable().min(0, 'недопустимое значение'),
})


const QUESTION_TYPES = [
  { value: 'single', name: '1 правильный ответ'},
  { value: 'multiple', name: 'несколько ответов'},
]

export const INITIAL_QUESTION_DATA = {
  single: {
    question_type: 'single',
    question_text: 'Текст вопроса',
    description: '',
    points: null,
    comment: '',
    answers: [
      {
        answer_text: 'Вариант 1',
        is_correct: true,
        points: null
      },
      {
        answer_text: 'Вариант 2',
        is_correct: false,
        points: null
      },
      {
        answer_text: 'Вариант 3',
        is_correct: false,
        points: null
      },
    ]
  },
  multiple: {
    question_type: 'multiple',
    question_text: 'Текст вопроса',
    description: '',
    points: null,
    comment: '',
    answers: [
      {
        answer_text: 'Вариант 1',
        is_correct: true,
        points: null
      },
      {
        answer_text: 'Вариант 2',
        is_correct: false,
        points: null
      },
      {
        answer_text: 'Вариант 3',
        is_correct: false,
        points: null
      },
    ]
  }
}


const QuestionModal = ({open, closeModal, questionId, addMode, testId}) => {
  
  const dispatch = useDispatch()
  const { current: data } = useSelector(state => state.editor.questions)

  useEffect(() => {
    if (!open) return;
    setValues(INITIAL_QUESTION_DATA.single)
    !addMode && dispatch(getCurrentQuestion(questionId))
  }, [open])
  
  useEffect(() => {
    data && setValues(data)
  }, [data])
  

  const createQuestionHandler = async ({points, ...data}) => {
    const payload = {
      ...data,
      test_id: testId,
      points: points || null
    }
    await dispatch(createQuestion(payload))
    closeModal()
  }

  const updateQuestionHandler = async ({id, points, ...data}) => {
    const payload = {...data, points: points || null}
    await dispatch(updateQuestion(id, payload))
    closeModal()
  }

  const questionForm = useFormik({
    initialValues: INITIAL_QUESTION_DATA.single,
    validationSchema,
    onSubmit: values => {
      if (pointsNotValid) return createNotification('error', 'Сумма баллов не соответствует баллу за вопрос вцелом');
      addMode ? createQuestionHandler(values) : updateQuestionHandler(values)
    }
  })

  const { values, setValues, handleSubmit } = questionForm

  const selectTypeHandler = (type) => () => {
    const values = INITIAL_QUESTION_DATA[type] 
    setValues(addMode ? values : { ...values, id: questionId })
  }

  const renderType = ({name, value}) => <Checkbox label={name} value={value===values.question_type} onChange={selectTypeHandler(value)}/>

  const pointsNotValid = values.question_type === 'multiple' && Number(values.points) && values.answers.reduce((acc, answer) => acc + Number(answer.points), 0) !== Number(values.points)

  return (
    <QuestionContext.Provider value={questionForm}>
      <Modal
        open={open}
        onClose={closeModal}
        classNames={{ modal: styles.modal }}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        showCloseIcon={false}
      >

        <h1>{!!values.id ? 'Редактирование вопроса' : 'Добавление вопроса'}</h1>

        <div className={styles.typesBlock}>
          <h3>Выберите тип вопроса</h3>
          <div>{QUESTION_TYPES?.map(renderType)}</div>
        </div>

        {values.question_type === 'single' ? <ChoiceQuestion /> : <MultipleQuestion pointsNotValid={pointsNotValid} />}

        <div className={styles.buttonsBlock}>
          <Button text={addMode ? 'Добавить' : 'Сохранить'} onClick={handleSubmit}/>
          <Button text='Закрыть' onClick={closeModal} mode='danger' />
        </div>

      </Modal>
    </QuestionContext.Provider>
  )
}

export default QuestionModal