import React, { useContext, useEffect, useState } from 'react'
import styles from '../styles.module.scss'
import { TestContext } from '../../../utils/context'
import Loader from 'react-loader';
import Button from '../../../components/Button'
import { useDispatch } from 'react-redux';
import Checkbox from '../../../components/CustomCheckbox/CustomCheckbox';
import RadioButton from '../../../components/Checkbox';
import { PieChart, Pie, Cell } from 'recharts';
import { interpolateColor } from '../../../utils/smallHelpers';
import { IoIosArrowDown } from "react-icons/io";


const FinalWindow = () => {
  
  const dispatch = useDispatch()
  const {closeTestHandler, getCertificate, stat, testId} = useContext(TestContext)

  useEffect(() => {
    getStatisticAction()
  }, [])

  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState(null)
  const [statOpen, setStatOpen] = useState(false)


  const getStatisticAction = async () => {
    setLoading(true)
    const certificate = await dispatch(getCertificate(testId))
    certificate && setFile(certificate)
    setLoading(false)
  }
  
  function loadCertificateHandler() {
    const url = window.URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'certificate');
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  }

  const renderAnswer = ({answer_text, is_correct, user_answer}) => {
    const wrong = !is_correct && user_answer
    const color = wrong ? 'red' : is_correct ? 'rgb(6 181 6)' : undefined
    return (
      <RadioButton color={color} label={answer_text} value={is_correct}/>
    )
  }
  
  const renderMultichoiceAnswer = ({answer_text, is_correct, user_answer}) => {
    const wrong = !is_correct && user_answer
    const color = wrong ? 'red' : is_correct ? 'rgb(6 181 6)' : undefined
    return (
      <Checkbox color={color} label={answer_text} value={is_correct}/>
    )
  }

  const renderQuestion = (question) => {
    const answerRenderCB = question.question_type === 'multiple' ? renderMultichoiceAnswer : renderAnswer
    return (
      <li key={question.id}>
        <p>{question.question_text}</p>
        <div>{question.answers.map(answerRenderCB)}</div>
        {question.comment && <div>{question.comment}</div>}
      </li>
    )
  }

  const wrongAnswers = () => {
    if (!stat) return;
    const arrowStyles = {transform: `rotate(${!statOpen ? '0' : '180deg'})`}
    return (
      <div className={styles.wrongQuestionsBlock}>
        <h2 onClick={() => setStatOpen(!statOpen)}>
          Просмотреть допущенные ошибки:
          <IoIosArrowDown style={arrowStyles} size={34}/>
        </h2>
        <ul style={{height: statOpen ? '100%' : '0px'}}>{stat.user_answers_to_questions.map(renderQuestion)}</ul>
      </div>
    )
  }
  
  const renderChart = () => {
    if (!stat) return;
    const {total_user_points, max_points} = stat
    const data = [
      { name: 'Получено баллов', value: total_user_points },
      { name: 'Недополучено баллов', value: max_points - total_user_points },
    ];
    const actualColor = interpolateColor(total_user_points / max_points)
    return (
      <>
        <div>
          <span style={{color: actualColor}}>{total_user_points} / {max_points}</span>
        </div>
        <PieChart width={200} height={200}>
          <Pie
            dataKey="value"
            data={data}
            outerRadius={80}
            innerRadius={40}
          >
            {data.map((_, ind) => <Cell fill={ind ? '#f5f5f5' : actualColor}/>)}
          </Pie>
        </PieChart>
      </>
    )
  }


  return (
    <div>
      <div className={styles.finalWindow}>
        {loading && <Loader/>}
        {!loading && 
          <>
            <h1>{file ? 'Тест пройден' : 'Тест не пройден'}</h1>
            <div>{renderChart()}</div>
            {file && <button onClick={loadCertificateHandler}>Получить сертификат</button>}
            {wrongAnswers()}
          </>
        } 
      </div>
      <div className={styles.buttonsBlock}>
        <Button onClick={closeTestHandler} text='Завершить' mode='danger'/>
      </div>
    </div>
  )
}

export default FinalWindow


